import gql from "graphql-tag";

export const CLOSE_WEPA_PORTFOLIO = gql`
  mutation CloseWepaPortfolio(
    $companyId: ID!
    $fiscalYearStart: String!
    $fiscalYearEnd: String!
    $portfolioId: Int!
    $closedOn: Date2!
    $closedReason: String!
  ) {
    closeWepaPortfolio(
      companyId: $companyId
      fiscalYearStart: $fiscalYearStart
      fiscalYearEnd: $fiscalYearEnd
      portfolioId: $portfolioId
      closedOn: $closedOn
      closedReason: $closedReason
    ) {
      ok
    }
  }
`;
