import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BlankPage } from "client/components/templates/BlankPage/BlankPage";
import GuestLayout from "sharedComponents/templates/GuestLayout/GuestLayout";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../global-query-types";
import { useHistory } from "react-router-dom";
import { QueryResult } from "react-apollo";
import { MeQuery } from "../../../lib/api/queries/graphql/MeQuery";
import { AlertType, RideAlertBar } from "../../../uiLibrary/components/RideAlertBar/RideAlertBar";

interface BookingGmbHProps extends WithTranslation {
  meRequest?: QueryResult<MeQuery>;
}

const BookingGmbHScene = ({ t, meRequest }: BookingGmbHProps) => {
  const sunsetOldFlow = FeatureFlagService.instance.isEnabled(FeatureFlags.SunsetOldOrderFlows);
  const history = useHistory();

  if (sunsetOldFlow && !meRequest?.data?.me?.id) {
    history.push("/order/account-creation/start");
  }
  return (
    <GuestLayout>
      <BlankPage title={t("trader-gmbh:booking-gmbh-title")}>
        {/*<BookingRedirectContainer />*/}
        {/*<BookingGmbHFlowContainer />*/}
        <RideAlertBar
          className={"company-foundation-flow__technical-issue-box"}
          type={AlertType.INFO}
          message={
            "Leider kann diese Leistung momentan nicht gebucht werden. Bitte versuchen Sie es später noch einmal"
          }
        />
      </BlankPage>
    </GuestLayout>
  );
};

export default withTranslation(["generic", "trader-gmbh"])(BookingGmbHScene);
