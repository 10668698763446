import { withTranslationReady } from "common/i18n/withTranslationReady";
import React, { useState } from "react";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { Trans, useTranslation, WithTranslation } from "react-i18next";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import ThirdPartyTaxAdvisorAddress, {
  ThirdPartyTaxAdvisorAddressData
} from "thirdPartyTaxAdvisor/components/ThirdPartyTaxAdvisorAddress/ThirdPartyTaxAdvisorAddress";
import PaymentAndContractSection from "thirdPartyTaxAdvisor/components/PaymentAndContractSection/PaymentAndContractSection";
import { PaymentMethodType } from "payments-query-types";
import RideSteuerberatungInformationModal from "client/components/MigrateAdminServicePackage/ContractSignatureCard/RideSteuerberatungInformationModal/RideSteuerberatungInformationModal";

const moduleName = "migrate-3pta";

interface ThirdPartyTaxAdvisorMigrateProps extends WithTranslation {
  goToSuccessPage: () => void;
  downloadUnsignedContract: () => void;
  updatePaymentMethod: (paymentMethod: PaymentMethodType) => Promise<void>;
  signContract: () => void;
  saveAddress: (address: ThirdPartyTaxAdvisorAddressData) => Promise<void>;
  existingAddress?: ThirdPartyTaxAdvisorAddressData;
  getRedirectUrlHandler: () => Promise<{ redirectUrl: string } | null | undefined>;
  initStep?: 0 | 1;
}

const ThirdPartyTaxAdvisorMigrate = ({
  t,
  goToSuccessPage,
  downloadUnsignedContract,
  updatePaymentMethod,
  signContract,
  saveAddress,
  existingAddress,
  getRedirectUrlHandler,
  initStep = 0
}: ThirdPartyTaxAdvisorMigrateProps) => {
  const [step, setStep] = useState(initStep);

  const saveAddressAndGoToNextStep = async (address: ThirdPartyTaxAdvisorAddressData) => {
    await saveAddress(address);
    setStep(1);
  };

  return (
    <div data-testid={"migrate-3pta"} className={moduleName}>
      <Typography
        data-testid={"title"}
        category={"Headline"}
        size={200}
        weight={"Heavy"}
        className={`${moduleName}__page-title`}>
        {t(`generic:migrate-3pta.title`)}
      </Typography>
      <div className={`${moduleName}__content`}>
        <MigrationDescription step={step} />
        {step === 0 && (
          <ThirdPartyTaxAdvisorAddress
            saveAddress={saveAddressAndGoToNextStep}
            address={existingAddress}
          />
        )}
        {step === 1 && (
          <PaymentAndContractSection
            goToSuccessPage={goToSuccessPage}
            downloadUnsignedContract={downloadUnsignedContract}
            updatePaymentMethod={updatePaymentMethod}
            signContract={signContract}
            getRedirectUrlHandler={getRedirectUrlHandler}
          />
        )}
      </div>
    </div>
  );
};

const MigrationDescription = ({ step }: { step: 0 | 1 }) => {
  const { t } = useTranslation("generic");
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <RideSteuerberatungInformationModal
          useRideSteuerberatung={true}
          onClose={() => setShowModal(false)}
        />
      )}
      <Typography data-testid={"description"} category={"Paragraph"} size={100} weight={"Light"}>
        <Trans
          i18nKey={
            step === 0 ? `generic:migrate-3pta.description_0` : `generic:migrate-3pta.description_1`
          }
          components={{
            bullet: <div className={`${moduleName}__bullet-point`} />,
            bold: <strong />,
            contactUs: (
              <TextLink
                data-testid={"contact-us"}
                variant={LinkVariant.primary}
                to={t(`generic:contact.email.href`)}
                target={"_blank"}
                rel={"noopener noreferrer"}
              />
            ),
            Impressum: <u role="button" onClick={() => setShowModal(true)} />
          }}
        />
      </Typography>
    </>
  );
};

export default withTranslationReady(["generic"])(ThirdPartyTaxAdvisorMigrate);
