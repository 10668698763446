import React, { useState } from "react";
import { useRideFormik } from "../../../lib/hooks/useRideFormik";
import {
  mapPaymentMethodToPaymentsModule,
  PaymentMethod
} from "../../../lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { Form, FormikProvider } from "formik";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../uiLibrary/designSystem/RideButton/RideButton";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Trans, WithTranslation } from "react-i18next";
import { Typography } from "../../../uiLibrary/designSystem/styles/Typography/Typography";
import { DownloadIcon } from "../../../sharedComponents/icons/DesignSystemIcons/DownloadIcon";
import {
  RideCheckbox,
  RideCheckboxVariant
} from "../../../uiLibrary/components/RideCheckbox/RideCheckbox";
import { PaymentOption } from "../../../client/components/MigrateAdminServicePackage/SetupPaymentCard/PaymentOption/PaymentOption";
import localStore from "../../../common/LocalStore";
import { LocationHelperProps, withLocationHelper } from "../../../common/LocationHelper";
import { PartyPopper } from "../../../sharedComponents/icons/PartyPopper";
import { PaymentMethodType } from "../../../payments-query-types";

export interface PaymentAndContractSectionProps {
  goToSuccessPage: () => void;
  downloadUnsignedContract: () => void;
  updatePaymentMethod: (paymentMethod: PaymentMethodType) => Promise<void>;
  signContract: () => void;
  getRedirectUrlHandler: () => Promise<{ redirectUrl: string } | null | undefined>;
}

const moduleName = "migrate-3pta";

const PaymentAndContractSection = ({
  t,
  goToSuccessPage,
  downloadUnsignedContract,
  updatePaymentMethod,
  signContract,
  getRedirectUrlHandler,
  locationHelper
}: PaymentAndContractSectionProps & WithTranslation & LocationHelperProps) => {
  const [hasDownloadedContract, setHasDownloadedContract] = useState(false);

  const downloadContract = async () => {
    downloadUnsignedContract();
    setHasDownloadedContract(true);
  };

  const formik = useRideFormik({
    initialValues: {
      paymentMethod: PaymentMethod.Sepa
    },
    onSubmit: async () => {
      if (formik.values["paymentMethod"] === PaymentMethod.ManualBankTransfer) {
        await updatePaymentMethod(mapPaymentMethodToPaymentsModule(formik.values["paymentMethod"]));
        signContract();
        goToSuccessPage();
      } else {
        const rememberToken = localStore.getValue("token-remember");
        if (!rememberToken) {
          localStore.setValue("token-remember", true);
          localStore.setValue("token-remember-set-for-stripe", true);
        }

        const stripeUrl = await getRedirectUrlHandler();
        if (stripeUrl) {
          locationHelper.redirect(stripeUrl.redirectUrl);
        }
      }
    }
  });

  const submitButtonLabel =
    formik.values["paymentMethod"] === PaymentMethod.Sepa
      ? t("generic:migrate-3pta.submit.accept-and-setup-payment")
      : t("generic:migrate-3pta.submit.send-information");

  const canSubmit =
    !!formik.values["paymentMethod"] &&
    !!formik.values["agreeToCompensationAgreement"] &&
    !!formik.values["acknowledgeInformationObligations"] &&
    !!formik.values["consentToClaimsAssignment"] &&
    !!formik.values["agreeToConfidentialityRelease"];

  return (
    <FormikProvider value={formik}>
      <Form>
        <SelectPaymentMethodSection />
        <DownloadAndAgreeContractSection
          downloadContract={downloadContract}
          disableCheckbox={!hasDownloadedContract}
        />
        <RideButton
          disabled={!canSubmit}
          className={`${moduleName} ${moduleName}__agree`}
          data-testid={"sign-contract-button"}
          label={submitButtonLabel}
          variant={RideButtonVariant.PRIMARY}
          size={RideButtonSize.BIG}
        />
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(withLocationHelper(PaymentAndContractSection));

const SelectPaymentMethodSection = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div className={`${moduleName}__section`} data-testid="payment-method-section">
      <Typography category="Headline" data-testid={`setup-payment-title`} size={300} weight="Heavy">
        {t("generic:payment-method-card.method-choice.title")}
      </Typography>
      <div className={`setup-payment-card__options ${moduleName}__payment-section__options`}>
        <PaymentOption
          data-testid={"sepa-payment-option"}
          name={"paymentMethod"}
          value={PaymentMethod.Sepa}
          label={t("generic:payment-method-card.method-choice.sepa.name")}
          feeMessage={t("generic:free")}
          icon={<PartyPopper />}
        />
        <PaymentOption
          data-testid={"manual-bank-transfer-payment-option"}
          name={"paymentMethod"}
          value={PaymentMethod.ManualBankTransfer}
          label={t("generic:payment-method-card.method-choice.manual.name")}
          feeMessage={t("generic:payment-method-card.method-choice.manual.name-suffix")}
        />
      </div>
    </div>
  );
});

const DownloadAndAgreeContractSection = withTranslationReady(["generic"])(
  ({
    t,
    downloadContract,
    disableCheckbox
  }: {
    downloadContract: () => void;
    disableCheckbox: boolean;
  } & WithTranslation) => {
    return (
      <div className={`${moduleName}__section`} data-testid={"contract-section"}>
        <Typography
          category="Headline"
          data-testid={`download-section-title`}
          size={300}
          weight="Heavy">
          {t("generic:migrate-3pta.new-contract-section.title")}
        </Typography>
        <Typography
          data-testid={"download-section-description"}
          category={"Paragraph"}
          size={100}
          weight={"Light"}>
          <Trans i18nKey={"generic:migrate-3pta.new-contract-section.description"} />
        </Typography>
        <RideButton
          type={"button"}
          data-testid={"download-contract-button"}
          leftIcon={<DownloadIcon />}
          label={t(
            `generic:migrate-admin-service-package.steps.contract-signature.download-contract-button`
          )}
          variant={RideButtonVariant.SECONDARY}
          size={RideButtonSize.BIG}
          className={"contract-signature-card__button-center"}
          onClick={downloadContract}
        />

        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          <Trans
            i18nKey={"generic:migrate-3pta.checkboxes.section1"}
            components={{ bold: <strong /> }}
          />
        </Typography>
        <RideCheckbox
          isReadonly={disableCheckbox}
          name={"agreeToCompensationAgreement"}
          variant={RideCheckboxVariant.Light}
          label={
            <Trans
              i18nKey={t(`generic:migrate-3pta.checkboxes.agreeToCompensationAgreement`)}
              components={{
                text: <Typography size={100} weight={"Light"} category={"Paragraph"} />
              }}
            />
          }
        />

        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          <Trans
            i18nKey={"generic:migrate-3pta.checkboxes.section2"}
            components={{ bold: <strong /> }}
          />
        </Typography>
        <RideCheckbox
          isReadonly={disableCheckbox}
          name={"acknowledgeInformationObligations"}
          variant={RideCheckboxVariant.Light}
          label={
            <Trans
              i18nKey={t(`generic:migrate-3pta.checkboxes.acknowledgeInformationObligations`)}
              components={{
                text: <Typography size={100} weight={"Light"} category={"Paragraph"} />
              }}
            />
          }
        />
        <RideCheckbox
          isReadonly={disableCheckbox}
          name={"consentToClaimsAssignment"}
          variant={RideCheckboxVariant.Light}
          label={
            <Trans
              i18nKey={t(`generic:migrate-3pta.checkboxes.consentToClaimsAssignment`)}
              components={{
                text: <Typography size={100} weight={"Light"} category={"Paragraph"} />
              }}
            />
          }
        />

        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          <Trans
            i18nKey={"generic:migrate-3pta.checkboxes.section3"}
            components={{ bold: <strong /> }}
          />
        </Typography>
        <RideCheckbox
          isReadonly={disableCheckbox}
          name={"agreeToConfidentialityRelease"}
          variant={RideCheckboxVariant.Light}
          label={
            <Trans
              i18nKey={t(`generic:migrate-3pta.checkboxes.agreeToConfidentialityRelease`)}
              components={{
                text: <Typography size={100} weight={"Light"} category={"Paragraph"} />
              }}
            />
          }
        />
      </div>
    );
  }
);
