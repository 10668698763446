import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

interface Props {
  titleTag: string;
  descriptionTag: string | string[];
  children?: React.ReactNode;
}

export const ProductPlaceholderPage = ({ titleTag, descriptionTag, children }: Props) => {
  useTranslation("generic");

  const descriptionLabels = descriptionTag instanceof Array ? descriptionTag : [descriptionTag];

  return (
    <div data-testid="product-placeholder-page" className={"product-placeholder-page pt-5"}>
      <Typography category={"Headline"} size={200} weight={"Heavy"}>
        <Trans i18nKey={titleTag} />
      </Typography>
      {descriptionLabels.map((label) => (
        <Typography key={label} category={"Paragraph"} size={200} weight={"Light"}>
          <Trans
            i18nKey={label}
            components={{
              bullet: "• ",
              bold: <strong />
            }}
          />
        </Typography>
      ))}
      {children && <div className="mt-3">{children}</div>}
    </div>
  );
};
