import { getToken } from "common/Authentication/getToken";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { useState } from "react";

export const useUploadFile = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<{ httpStatus: number } | undefined>(undefined);

  async function uploadFile(companyId: string, broker: string, taskId: string, file: File) {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("taskId", taskId);
    formData.append("broker", broker);

    setIsUploading(true);

    try {
      const response = await fetch(`${getEndpoint()}/api/wepa/import/${companyId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body: formData,
        mode: "cors",
        credentials: "include"
      });

      if (!response.ok) {
        setError({ httpStatus: response.status });
        return;
      }

      const responseData = (await response.json()) as { uploadId: string };

      return responseData;
    } catch {
      setError({ httpStatus: 0 });
    }

    setIsUploading(false);
  }

  return {
    isUploading,
    error,
    uploadFile
  };
};
