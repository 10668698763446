import React from "react";
import { LowTaxBrokerViewSwitcher } from "client/components/LowTaxBrokerViewSwitcher/LowTaxBrokerViewSwitcher";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { GetLTBOrderForCompany } from "lib/api/queries/GetLowTaxBrokerOrderForCompany";
import { BrokerageAccount } from "lib/types/types";
import { GmbHDetails } from "../../../lib/models/client/GmbHDetails";
import { FeatureFlags } from "global-query-types";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { ProductPlaceholderPage } from "../ProductPlaceholderPage/ProductPlaceholderPage";

export const LowTaxBrokerViewSwitcherContainer = ({
  gmbh,
  brokerageAccounts
}: {
  gmbh: GmbHDetails;
  brokerageAccounts: BrokerageAccount[] | null;
}) => {
  const getLowTaxBrokerOrderQuery = GetLTBOrderForCompany(gmbh.id);

  const order = getLowTaxBrokerOrderQuery?.data?.getLowTaxBrokerOrderForCompany;

  const isBrokerAvailable = isBrokerSectionAvailable(gmbh);

  if (FeatureFlagService.instance.isEnabled(FeatureFlags.WealthNavigation) && !isBrokerAvailable) {
    return (
      <ProductPlaceholderPage
        titleTag="generic:product-unavailable-placeholder.broker.title"
        descriptionTag={[
          "generic:product-unavailable-placeholder.broker.description",
          "generic:product-unavailable-placeholder.broker.description-2"
        ]}
      />
    );
  }

  return (
    <RequestFeedbackHandler requests={[getLowTaxBrokerOrderQuery]}>
      <LowTaxBrokerViewSwitcher gmbh={gmbh} order={order} brokerageAccounts={brokerageAccounts} />
    </RequestFeedbackHandler>
  );
};

const isBrokerSectionAvailable = (gmbh: GmbHDetails) => {
  const showInvestForCompaniesWithCorporateShareholders = FeatureFlagService.instance.isEnabled(
    FeatureFlags.CorporateShareholderCompaniesForLowTaxBrokerOrders
  );

  return (
    showInvestForCompaniesWithCorporateShareholders || !gmbh.shareholders?.find((m) => !!m.company)
  );
};
