import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { ArrowRight } from "sharedComponents/icons/DesignSystemIcons/ArrowRight";
import { PhoneFilledIcon } from "sharedComponents/icons/DesignSystemIcons/PhoneFilledIcon";
import NavigationService from "lib/services/NavigationService/NavigationService";

interface WepaUpsellProps extends WithTranslation {
  goToWepaOnboarding: () => void;
}

const WepaUpsell = ({ t, goToWepaOnboarding }: WepaUpsellProps) => {
  const features = t("generic:wepa-upsell.features", { returnObjects: true });

  return (
    <div data-testid="wepa-upsell" className={"wepa-upsell"}>
      <Typography
        data-testid={"wepa-upsell-title"}
        category={"Headline"}
        size={200}
        weight={"Heavy"}>
        {t("generic:wepa-upsell.title")}
      </Typography>
      <Typography
        data-testid={"wepa-upsell-description"}
        category={"Paragraph"}
        size={200}
        weight={"Light"}>
        <Trans i18nKey={"generic:wepa-upsell.description"} />
      </Typography>
      <ul className={"wepa-upsell__feature-list"}>
        {Object.keys(features).map((featureKey, index) => {
          return (
            <li className={"wepa-upsell__feature"} key={`feature-${index}`}>
              <Typography
                data-testid="wepa-upsell-item"
                category={"Paragraph"}
                size={200}
                weight={"Light"}
                className={"wepa-upsell__feature-text"}>
                <Trans i18nKey={`generic:wepa-upsell.features.${featureKey}`} />
              </Typography>
            </li>
          );
        })}
      </ul>
      <div>
        <RideButton
          data-testid={"learn-more-button"}
          label={
            <Trans i18nKey={"generic:wepa-upsell.learn-more"} components={{ bold: <strong /> }} />
          }
          variant={RideButtonVariant.GHOST}
          size={RideButtonSize.BIG}
          rightIcon={<ArrowRight />}
          onClick={() =>
            NavigationService.instance?.openLink(t("generic:wertpapierverbuchung-url-b2c"))
          }
        />
      </div>
      <div className={"wepa-upsell__price"}>
        <Typography
          data-testid={"wepa-upsell-price"}
          category={"Headline"}
          size={300}
          weight={"Heavy"}>
          {t("generic:wepa-upsell.price")}
        </Typography>
        <Typography
          data-testid={"wepa-upsell-bill-frequency"}
          category={"Paragraph"}
          size={200}
          weight={"Light"}>
          {t("generic:wepa-upsell.bill-frequency")}
        </Typography>
      </div>
      <div className="wepa-upsell__ctas">
        <RideButton
          data-testid={"book-a-call-button"}
          className={"wepa-upsell__cta"}
          label={t("generic:wepa-upsell.book-a-call")}
          variant={RideButtonVariant.SECONDARY}
          size={RideButtonSize.BIG}
          leftIcon={<PhoneFilledIcon size={16} />}
          style={{ display: "none" }}
          onClick={() => NavigationService.instance?.openLink(t("generic:book-a-call-url"))}
        />
        <RideButton
          data-testid={"buy-now-button"}
          className={"wepa-upsell__cta"}
          label={t("generic:wepa-upsell.buy-now")}
          variant={RideButtonVariant.PRIMARY}
          size={RideButtonSize.BIG}
          rightIcon={<ArrowRight />}
          disabled={true}
          style={{ backgroundColor: "#fafafa" }}
          onClick={goToWepaOnboarding}
        />
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(WepaUpsell);
