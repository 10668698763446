import { CLOSE_WEPA_PORTFOLIO } from "lib/api/mutations/CLOSE_WEPA_PORTFOLIO";
import {
  CloseWepaPortfolio,
  CloseWepaPortfolioVariables
} from "lib/api/mutations/graphql/CloseWepaPortfolio";
import { useMutation } from "react-apollo";

export const useCloseWepaPortfolio = () => {
  const [closeWepaPortfolio] = useMutation<CloseWepaPortfolio, CloseWepaPortfolioVariables>(
    CLOSE_WEPA_PORTFOLIO
  );

  return { closeWepaPortfolio };
};
