import { CompanyById_companyById } from "../../../lib/api/queries/graphql/CompanyById";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation, useTranslation } from "react-i18next";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { RideBadge } from "uiLibrary/components/RideBadge/RideBadge";
import { StepProgressBar } from "../../../sharedComponents/StepProgressBar/StepProgressBar";
import { Col, Row } from "react-bootstrap";
import {
  CopyableInformation,
  CopyableInformationVariant
} from "../CopyableInformation/CopyableInformation";

const moduleName = "lei-number-section";

const LeiNumberStatusBadge = ({ status }: { status: string }) => {
  const { t } = useTranslation("lei-number");

  switch (status) {
    case "COMPLETED":
      return <RideBadge variant="GREEN" label={t("lei-number:status:completed:badge")}></RideBadge>;
    case "IN_PROGRESS":
      return (
        <RideBadge variant="OCRE" label={t("lei-number:status:in-progress:badge")}></RideBadge>
      );
    case "DELAYED":
      return <RideBadge variant="OCRE" label={t("lei-number:status:delayed:badge")}></RideBadge>;
    case "ACTION_REQUIRED":
      return (
        <RideBadge
          variant="PURPLE"
          label={t("lei-number:status:action-required:badge")}></RideBadge>
      );
    case "REJECTED":
      return <RideBadge variant="RED" label={t("lei-number:status:rejected:badge")}></RideBadge>;
    default:
      return null;
  }
};

function getBodyTranslationTag(status: string) {
  switch (status) {
    case "COMPLETED":
      return "lei-number:status:completed:body";
    case "IN_PROGRESS":
      return "lei-number:status:in-progress:body";
    case "DELAYED":
      return "lei-number:status:delayed:body";
    case "ACTION_REQUIRED":
      return "lei-number:status:action-required:body";
    case "REJECTED":
      return "lei-number:status:rejected:body";
  }
}

export interface LeiNumberIssueStatusSectionProps extends WithTranslation {
  leiPartnerLogoPath?: string;
  leiSupportContactEmail: string;
  leiSupportContactEmailLabel: string;
  company: CompanyById_companyById;
}

export const LeiNumberIssuedSection = ({ company }: { company: CompanyById_companyById }) => {
  const { t } = useTranslation("generic");
  const isLeiNumberIssued = !!company.lei;

  return (
    <div className={`${moduleName} my-5 container`} data-testid="lei-number-issue-status-section">
      <CardContainer className="p-5">
        <Row>
          <Col>
            <div className="pr-5" data-testid="lei-number-message">
              <Typography category="Paragraph" size={100} weight="Light">
                {t("lei-number:status:issued:body")}
              </Typography>
            </div>

            {isLeiNumberIssued && (
              <Row className="mt-5" data-testid="lei-number-provided">
                <Col lg={6} xs={12}>
                  <Typography category="Paragraph" size={100} weight="Heavy">
                    {t("lei-number:status:all-sections:your-lei-number")}
                  </Typography>
                  <div className={`${moduleName}--lei-number-card mt-2 px-5 py-4`}>
                    <CopyableInformation
                      label={company.lei!}
                      value={company.lei!}
                      showCopyButton={true}
                      showValue={false}
                      variant={CopyableInformationVariant.green}
                    />
                  </div>
                </Col>
              </Row>
            )}

            <div className="d-flex mt-5" data-testid="lei-number-badge">
              <Typography category="Paragraph" size={100} weight="Heavy" className="mr-2">
                {t("lei-number:status:all-sections:status-badge")}
              </Typography>
              <LeiNumberStatusBadge status="COMPLETED" />
            </div>
          </Col>

          {!isLeiNumberIssued && (
            <Col lg={3} className="mt-lg-0 mt-5" data-testid="lei-number-steps">
              <StepProgressBar
                steps={[
                  t("lei-number:status-bar:in-progress"),
                  t("lei-number:status-bar:in-review"),
                  t("lei-number:status-bar:completed")
                ]}
                currentStepIndex={2}
                mobileLayout="vertical"
              />
            </Col>
          )}
        </Row>
      </CardContainer>
    </div>
  );
};

const LeiNumberIssueStatusSection = ({
  t,
  leiPartnerLogoPath,
  leiSupportContactEmail,
  leiSupportContactEmailLabel,
  company
}: LeiNumberIssueStatusSectionProps) => {
  const leiIssueStatus =
    !!company.lei && company.leiIssueStatus === "IN_PROGRESS"
      ? "COMPLETED"
      : company.leiIssueStatus;

  const isLeiNumberIssued = !!company.lei && ["IN_PROGRESS", "COMPLETED"].includes(leiIssueStatus!);
  const isSupportMessageShown = ["DELAYED", "ACTION_REQUIRED", "REJECTED"].includes(
    leiIssueStatus!
  );

  return (
    <div className={`${moduleName} my-5 container`} data-testid="lei-number-issue-status-section">
      <CardContainer className="p-5">
        {!!leiPartnerLogoPath && (
          <Row className="mb-5">
            <Col xs={9} md={6} lg={3}>
              <img
                className={`w-100`}
                src={leiPartnerLogoPath}
                alt={"LEI Partner Logo"}
                data-testid="lei-partner-logo"
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="pr-5" data-testid="lei-number-message">
              <Typography category="Paragraph" size={100} weight="Light">
                {t(getBodyTranslationTag(leiIssueStatus!)!)}
              </Typography>
            </div>

            {isLeiNumberIssued && (
              <Row className="mt-5" data-testid="lei-number-provided">
                <Col lg={6} xs={12}>
                  <Typography category="Paragraph" size={100} weight="Heavy">
                    {t("lei-number:status:all-sections:your-lei-number")}
                  </Typography>
                  <div className={`${moduleName}--lei-number-card mt-2 px-5 py-4`}>
                    <CopyableInformation
                      label={company.lei!}
                      value={company.lei!}
                      showCopyButton={true}
                      showValue={false}
                      variant={CopyableInformationVariant.green}
                    />
                  </div>
                </Col>
              </Row>
            )}

            <div className="d-flex mt-5" data-testid="lei-number-badge">
              <Typography category="Paragraph" size={100} weight="Heavy" className="mr-2">
                {t("lei-number:status:all-sections:status-badge")}
              </Typography>
              <LeiNumberStatusBadge status={leiIssueStatus!} />
            </div>

            {isSupportMessageShown && (
              <div className="mt-5" data-testid="lei-number-contact">
                <Typography category="Paragraph" size={100} weight="Light">
                  {t("lei-number:status:all-sections:contact-support")}
                  <a
                    href={leiSupportContactEmail}
                    className="ml-1"
                    data-testid="lei-number-contact-email">
                    {leiSupportContactEmailLabel}
                  </a>
                </Typography>
              </div>
            )}
          </Col>

          {!isLeiNumberIssued && (
            <Col lg={3} className="mt-lg-0 mt-5" data-testid="lei-number-steps">
              <StepProgressBar
                steps={[
                  t("lei-number:status-bar:in-progress"),
                  t("lei-number:status-bar:in-review"),
                  t("lei-number:status-bar:completed")
                ]}
                currentStepIndex={1}
                mobileLayout="vertical"
              />
            </Col>
          )}
        </Row>
      </CardContainer>
    </div>
  );
};

export default withTranslationReady(["lei-number"])(LeiNumberIssueStatusSection);
