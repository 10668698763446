import React from "react";
import { PortfolioDataCompletenessTask } from "./PortfolioDataCompletenessTask";
import { GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany } from "lib/api/administration/queries/graphql/GetEndOfYearTasksByCompany";
import { useUploadFile } from "./hooks/useUploadFile";
import { useCloseWepaPortfolio } from "./hooks/useCloseWepaPortfolio";
import { usePortfolioDataCompleteness } from "./hooks/usePortfolioDataCompleteness";

interface Props {
  index: number;
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany;
}

export const PortfolioDataCompletenessTaskContainer = ({ index, task }: Props) => {
  const {
    portfolio,
    fileImport,
    isLoading,
    error: portfolioError,
    refetch
  } = usePortfolioDataCompleteness(task);
  const { uploadFile, isUploading, error: uploadError } = useUploadFile();

  async function onSelectFileUpload(file: File) {
    await uploadFile(task.companyId, portfolio?.broker!, task.id, file);
  }

  const { closeWepaPortfolio } = useCloseWepaPortfolio();
  const onCloseWepaPortfolio = async () => {
    await closeWepaPortfolio({
      variables: {
        companyId: task.companyId,
        fiscalYearStart: task.fiscalYearStart,
        fiscalYearEnd: task.fiscalYearEnd,
        portfolioId: parseInt(task.referenceId!),
        closedOn: new Date(),
        closedReason: `Closed task ${task.id}`
      }
    });

    await refetch();
  };

  return (
    <PortfolioDataCompletenessTask
      index={index}
      isLoading={isLoading}
      isUploading={isUploading}
      uploadError={uploadError}
      portfolioError={portfolioError}
      task={task}
      portfolio={portfolio}
      importStatus={fileImport}
      onSelectFileUpload={onSelectFileUpload}
      onCloseTask={() => {}}
      onClosePortfolio={onCloseWepaPortfolio}
      onRefresh={refetch}
    />
  );
};
